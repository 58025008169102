<template>
  <a-card>
    <a-page-header
      title="商品详情页滚动图"
      @back="() => this.$router.go(-1)" >
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
    </a-page-header>
    <s-table
      ref="table"
      size="small"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      rowKey="id"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="img" slot-scope="img">
        <a-avatar
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal :visible="previewvisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewurl" />
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/a-applet/shop_goods/goods_gallery/CreateForm'

import { shopGoodsGalleryList, shopGoodsGalleryCreate, shopGoodsGalleryEdit, shopGoodsGalleryDelete } from '@/api/applet_goods'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      previewvisible: false,
      previewurl: undefined,
      confirmLoading: true,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '图片',
          dataIndex: 'value',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return shopGoodsGalleryList(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.queryParam.goods_id = this.$route.query.id
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.mdl = record
      this.visible = true
      this.confirmLoading = false
    },
    handleDelete (record) {
      shopGoodsGalleryDelete(record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    openPreview (url) {
      if (url === undefined || url === '') {
        return
      }
      this.previewvisible = true
      this.previewurl = url
    },
    handleCancel () {
      this.previewvisible = false
      this.previewurl = undefined
    },
    handleOk (value) {
      if (value === undefined) {
        this.$message.warning('上传图片')
        return
      }
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl) {
            const data = {
              value: value
            }
            shopGoodsGalleryEdit(data, this.mdl.id).then((res) => {
              console.log(res, '修改成功')
              this.visible = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
              form.resetFields()
            })
          } else {
            const data = {
              goods_id: this.$route.query.id,
              value: value
            }
            shopGoodsGalleryCreate(data).then((res) => {
              console.log(res, '创建成功')
              this.visible = false
              this.$refs.table.refresh()
            }).finally(() => {
              this.confirmLoading = false
              form.resetFields()
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
